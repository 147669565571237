<div *ngIf="partDetail && parentFormGroup && this.answerControlName" [formGroup]="parentFormGroup" [id]="'id-' + part.id">
    <mat-form-field appearance="outline" dataFormat-name="form-code" subscriptSizing="dynamic">
        <mat-label>{{ partDetail.label }}</mat-label>
        <input
            matInput
            type="color"
            name="answer"
            align="end"
            [required]="partDetail.required"
            [formControlName]="this.answerControlName"
            (change)="onChangeAnswer()" />
        <mat-error
            *ngIf="
                parentFormGroup.controls[this.answerControlName].touched &&
                parentFormGroup.controls[this.answerControlName].invalid &&
                partDetail.required &&
                parentFormGroup.controls[this.answerControlName].hasError('required')
            "
            >{{ partDetail.requiredMessage }}</mat-error
        >
        <mat-icon
            class="help-icon"
            color="primary"
            *ngIf="partDetail.help"
            matSuffix
            [showTrigger]="NgxFloatUiTriggers.hover"
            appendTo="body"
            [floatUi]="questionInformationPopover"
            >help
        </mat-icon>
        <float-ui-content #questionInformationPopover>
            <div class="popover">
                <div [innerHTML]="partDetail.helpInformation"></div>
            </div>
        </float-ui-content>
    </mat-form-field>
</div>
